import React from "react";
import {Helmet} from "react-helmet";

import Layout from "components/Layout";
import Container from "../components/Container";
import {useSiteMetadata} from "hooks";

const NotFoundPage = () => {
    const {companyName} = useSiteMetadata();

    return (
        <Layout>
            <Helmet>
                <title>{companyName} | 404</title>
            </Helmet>
            <Container>
                <h1>Page Not Found</h1>
                <p>You just went off-trail. The route you followed doesn&apos;t exist. Are ya sure you&apos;re on the right
                    path?</p>
            </Container>
        </Layout>
    );
};

export default NotFoundPage;
